import React from "react";

function article_1() {
	return {
		date: "Sep - Dec 2019 ",
		title: "Learning Labs Support Student",
		location: "UBC Sauder School of Business · Part-time",
		description:
			"Worked on setting up of technical equipment, troubleshooting of malfunctioned gadgets, installing software and replacing damaged parts in lecture halls of UBC Sauder School of Business. Kept in touch with instructors and faculty members to allow them for smooth teaching experience and help them with any technical difficulties.",
		keywords: [
		],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">Content of article 1</div>
					<img
						src="https://picsum.photos/200/300"
						alt="random"
						className="randImage"
					/>
				</div>
			</React.Fragment>
		),
	};
}

function article_2() {
	return {
		date: "Feb 2020 - Apr 2021 ",
		location: "BlueStacks · Internship",
		title: "QA Game Tester Intern",
		description:
			"Tested applications on Bluestacks, platform with over 400 million users worldwide. Performed regression, functionality, compatibility testing on new games that are constantly released to make sure that they worked as expected on various FPS and graphic engines. Documented feedbacks on bugs and made sure that users have smooth gaming experience on the platform. Gained insights on improving user experience, communication skills, became a detail-oriented and a responsible person who is willing to take ownership of work.",
		style: ``,
		keywords: [
		
		],
		body: (
			<React.Fragment>
				<h1>Content of article 2</h1>
			</React.Fragment>
		),
	};
}


function article_3() {
	return {
		date: "Sep 2021 - Aug 2022",
		location: "5G Group of Companies · Co-op",
		title: "Full Stack Developer Intern",
		description:
			"Built frontend and backend system for PLC Logix, OPCUA, CESMII adapter for company’s BorgConnect platform. Used Python with Django framework for adapter backend using RESTful API. Developed unit testing tool using PyUnit framework. For database management, used SQLite, MySQL with RabbitMQ as message broker. Further, used Angular.js and Ionic framework for the development of frontend dashboard. This product I contributed to won the 'Global Product of the year award' in the Challenger category in Digital Engineering awards held in US.",
		style: ``,
		keywords: [
		
		],
		body: (
			<React.Fragment>
				<h1>Content of article 2</h1>
			</React.Fragment>
		),
	};
}

function article_4() {
	return {
		date: "May 2023 - Dec 2023",
		location: "輝翠TECH (Kisui TECH) · Internship",
		title: "Frontend Developer Intern",
		description: "• As a startup team member, created a cross-platform mobile application from scratch using React Native with Typescript. Implemented core features such as Custom Login, Sign Up, Profile, and Settings Screens with AWS integration for seamless sign-in/sign-up and profile management. In the Settings Screen, incorporated controls for robot. Additionally, developed a Map Screen using React Native MapView and MapBox GL (for offline access) to enable live-robot data and advanced map control.\n\n• Designed and developed a user-friendly web application using React.js. This application facilitated access to farmer information stored on AWS, provided live robot location visualization via Mapbox GL JS, and allowed for the modification of farm names and farmer profiles.\n\n• Developed ROS2 publisher and subscriber nodes and used Python's Socket library to establish bidirectional communication between frontend and backend applications. Effectively transferred robot map and status data, enhancing data flow and control capabilities using NVIDIA Jetson Xavier and Jetson Orin.\n\n• Conducted user testing and engaged with farmers from Gunma Prefecture, Japan, to gather valuable feedback, ensuring the development of user-centric solutions for the agricultural robot ecosystem.",
		style: ``,
		keywords: [
		
		],
		body: (
			<React.Fragment>
				<h1>Content of article 2</h1>
			</React.Fragment>
		),
	};
}

function article_5() {
	return {
		date: "Jan - Aug 2024",
		location: "Augmented Human Lab · Internship",
		title: "Research Intern",
		description: `
            • Conducted research on developing large-scale, high-performance backend for AI-powered wearable headset "AISee" to help people with visual impairment to navigate through grocery shopping.
            • Architected and developed a scalable, production-level backend API endpoints using Python, Flask, Redis, MongoDB, Docker, GCP, interfacing with OpenAI’s GPT-4 API that interacts with the headset.
        `,
		style: ``,
		keywords: [
		
		],
		body: (
			<React.Fragment>
				<h1>Content of article 2</h1>
			</React.Fragment>
		),
	};
}

function article_6() {
	return {
		date: "July 2024 - Present",
		location: "Heroic Design",
		title: "Software Engineer - Generative AI",
		description: `
            • Developing an AI-powered CAD software to streamline new product design processes, reducing design cycle times.
            • Created wireframes and prototypes in Figma for rapid iterations, enabling user-centric designs.
            • Built a highly responsive frontend with React TypeScript, Fluent UI v9, MSAL, and Pragmatic Drag-and-Drop, enhancing usability.
            • Designed scalable backend systems using Azure AI Studio, FastAPI, PostgreSQL, and App Service, considering performance and scalability.
        `,
		style: ``,
		keywords: [
		
		],
		body: (
			<React.Fragment>
				<h1>Content of article 6</h1>
			</React.Fragment>
		),
	};
}



const myArticles = [ article_6, article_5, article_4, article_3, article_2, article_1];

export default myArticles;

import React from "react";

function involvement_1() {
  return {
    date: "Apr 2019",
    title: "Design and Innovation Day Volunteer",
    location: "UBC Engineering, Applied Science",
    description:
      "Helped fourth year students to set up their capstone projects. Also conducted reception checkin and way finding to other guests at the various exhibits throughout the event",
    keywords: [
    ],
    style: `
        .involvement-content {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .randImage {
          align-self: center;
          outline: 2px solid red;
        }
        `,
    body: (
      <React.Fragment>
        <div className="involvement-content">
          <div className="paragraph">Content of involvement 1</div>
          <img
            src="https://picsum.photos/200/300"
            alt="random"
            className="randImage"
          />
        </div>
      </React.Fragment>
    ),
  };
}

function involvement_2() {
  return {
    date: "Feb 2019 - Mar 2019",
    title: "Event Organizer",
    location: "UBC Engineering, Faculty of Applied Science",
    description:
      "Organized sea to sky gondola trip event for first year engineering students, managed budgets, called authorities and planned the events.",
    style: ``,
    keywords: [
    ],
    body: (
      <React.Fragment>
        <h1>Content of involvement 2</h1>
      </React.Fragment>
    ),
  };
}

function involvement_3() {
  return {
    date: "Sep 2019",
    title: "Imagine Day Orientation Leader 2019",
    location: "The University of British Columbia",
    description:
      "Welcomed new students by building community while assisting with the organization and delivery of Imagine UBC activities.",
    style: ``,
    keywords: [
    ],
    body: (
      <React.Fragment>
        <h1>Content of involvement 2</h1>
      </React.Fragment>
    ),
  };
}

function involvement_4() {
  return {
    date: "Sep 2021",
    title: "Imagine Day Orientation Leader 2021",
    location: "The University of British Columbia",
    description:
      "Welcomed new students remotely by  organizing icebreaker games, answering questions while assisting with the organization and delivery of Imagine UBC activities.",
    style: ``,
    keywords: [
    ],
    body: (
      <React.Fragment>
        <h1>Content of involvement 2</h1>
      </React.Fragment>
    ),
  };
}

function involvement_5() {
  return {
    date: "Sep - April 2020",
    title: "Vantage Peer Mentor",
    location: "The University of British Columbia",
    description:
      "Envisioned, planned and implemented various events geared towards supporting the first year international students to build a sense of community within them. Helped students to expand their social support and assisted them with course materials through personal interactions and campus connections.",
    style: ``,
    keywords: [
    ],
    body: (
      <React.Fragment>
        <h1>Content of involvement 2</h1>
      </React.Fragment>
    ),
  };
}

function involvement_6() {
  return {
    date: "May 2022 - Sep 2022",
    title: "Senior Orientation Leader 2022",
    location: "UBC Engineering",
    description:
      "Worked on recruitment, training and oversight of student leaders involved in Imagine UBC. Organizing meetings among orientation leaders monthly for checkin/ preparation for Imagine Day.",
    style: ``,
    keywords: [
    ],
    body: (
      <React.Fragment>
        <h1>Content of involvement 2</h1>
      </React.Fragment>
    ),
  };
}

function involvement_7() {
  return {
    date: "Mar 2023",
    title: "Multidisciplinary Undergraduate Research Conference (MURC) 2023 Conference Volunteer",
    location: "MURC UBC",
    description:
      "Contributed to pre-event training and actively assisted in delegate and presenter check-in, guided way-finding, and hosted the Panel/AMA session. Managed name tag distribution, verified presenters' UBC cards, and recorded their details. Directed attendees to conference activities and moderated an engaging 'Aspire and Inspire' AMA, ensuring smooth operation and logistical support.",
    style: ``,
    keywords: [
    ],
    body: (
      <React.Fragment>
        <h1>Content of involvement 2</h1>
      </React.Fragment>
    ),
  };
}

function involvement_9() {
  return {
    date: "Sep 2023",
    title: "Move in Day Volunteer 2023",
    location: "UBC Student Housing and Community Services",
    description:
      "As a move-in day volunteer this year (and also last year) , my primary responsibility was helping students and their families in the process of transporting their belongings from the parking area to their new rooms. Beyond the physical aspect of moving, I've also taken the initiative to engage with the students and had conversations with them to ensure they feel comfortable and at ease during the move-in process and university life.",
    style: ``,
    keywords: [
    ],
    body: (
      <React.Fragment>
        <h1>Content of involvement 2</h1>
      </React.Fragment>
    ),
  };
}
function involvement_8() {
  return {
    date: "Sep 2022",
    title: "Move in Day Volunteer 2022",
    location: "UBC Student Housing and Community Services",
    description:
      "",
    style: ``,
    keywords: [
    ],
    body: (
      <React.Fragment>
        <h1>Content of involvement 2</h1>
      </React.Fragment>
    ),
  };
}

function involvement_10() {
  return {
    date: "May 2021 - April 2022",
    title: "Computer Science Tutor Volunteer",
    location: "C.O.D.E. Initiative",
    description:
      "Volunteered as a computer science tutor to students of age 6-17 who are from neurodivergent and neurotypical community with learning difficulties for C.O.D.E. initiative. It is a former Ubc student lead non-profit organization. I prepared for and offered App Development , Web Development and Java courses to the students and made sure they followed and have good understanding of the lessons as their learning pace.",
    style: ``,
    keywords: [
    ],
    body: (
      <React.Fragment>
        <h1>Content of involvement 2</h1>
      </React.Fragment>
    ),
  };
}

function involvement_11() {
  return {
    date: "Sep 2021 - April 2022",
    title: "Technical Lead",
    location: "UBC Smart City",
    description:
      "Leaded a team to create a web app to do real-time queue detection using ML model which anticipate wait time and crowding in public spaces . Worked on Yolov4 model and Tensorflow Cumulative Object Counting APIs for object detection on videos captured by Nvidia Jetson Nano SBC and used Streamlit to create UI Web App.",
    style: ``,
    keywords: [
    ],
    body: (
      <React.Fragment>
        <h1>Content of involvement 2</h1>
      </React.Fragment>
    ),
  };
}


function involvement_12() {
  return {
    date: "May 2022 - April 2023",
    title: "Executive & Transportation Team Lead",
    location: "UBC Smart City",
    description:
      "Managed and leaded a team to work on UBC Safewalk App, an app that connects student with Safe walker. Using Expo, React Native for frontend and Flask and MongoDB for backend to build cross platform application.",
    style: ``,
    keywords: [
    ],
    body: (
      <React.Fragment>
        <h1>Content of involvement 2</h1>
      </React.Fragment>
    ),
  };
}


function involvement_13() {
  return {
    date: "May 2023 - April 2024",
    title: "Captain",
    location: "UBC Smart City",
    description:
      "As a primary captain, managing team wide operation, responsible for organizing team meetings, leading and guiding transportation and energy sub-teams, participating in competitions, doing social media promotion, getting funding/sponsorship for the team.",
    style: ``,
    keywords: [
    ],
    body: (
      <React.Fragment>
        <h1>Content of involvement 2</h1>
      </React.Fragment>
    ),
  };
}
function involvement_14() {
  return {
    date: "Sep 2023 - April 2024",
    title: "Chair Person",
    location: "ACM SIGCHI@UBC Student Chapter",
    description:
      "Recruited executives, gathered members and led a student club since September that brings together individuals from diverse academic backgrounds, including Psychology, Library and Information Science, and Computer Engineering, all united on a common interest in Human-Computer Interaction (HCI).",
    style: ``,
    keywords: [
    ],
    body: (
      <React.Fragment>
        <h1>Content of involvement 2</h1>
      </React.Fragment>
    ),
  };
}

const myInvolvements = [ involvement_13, involvement_14, involvement_9, involvement_12, involvement_7, involvement_8, involvement_6, involvement_11, involvement_10, involvement_4, involvement_5, involvement_3, involvement_2, involvement_1];

export default myInvolvements;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faGithub
} from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";

function createResearch(viewPaper, viewWebsite, date, title, description, imgPath, keywords, style = "", paperLink = "", websiteLinks = []) {
    return () => ({
        date,
        title,
        description,
        keywords,
        style,
        body: (
            <React.Fragment>
                <div className="research-content">
                    {viewPaper && (
                        <div className="social">
                            <a href={paperLink} target="_blank" rel="noreferrer">
                                <div className="social-icon">
                                    <FontAwesomeIcon
                                        icon={faGithub}
                                        className="social-icon"
                                    />
                                </div>
                                <div className="social-text">View paper on GitHub</div>
                            </a>
                        </div>
                    )}
                    {viewWebsite && websiteLinks.length > 0 && (
                        <div style={{ display: "flex", alignItems: "center", gap: "15px", marginBottom: "20px" }}>
                        {websiteLinks.map((link, index) => (
                            <a
                                key={index}
                                href={link}
                                target="_blank"
                                rel="noreferrer"
                                style={{ display: "flex", alignItems: "center", textDecoration: "none" }}
                            >
                                <div style={{ fontSize: "18px", marginRight: "8px" }}>
                                    <FontAwesomeIcon icon={faLink} />
                                </div>
                                <div style={{ fontSize: "14px" }}>View on Website {index + 1}</div>
                            </a>
                        ))}
                    </div>
                    
                    )}
                    <img
                        src={imgPath}
                        alt="research"
                        className="randImage"
                        width="80%"
                    />
                    <div className="paragraph">{description}</div>
                </div>
            </React.Fragment>
        ),
    });
}

const myResearch = [
    createResearch(
        false,
        true,
        "Jan - April 2023",
        "AISee Project Contributions",
        "Analyzed the AISee research paper to design backend structure, user workflows, and APIs for AI-based wearable headset to help visually impaired people with daily tasks. Conducted comparative studies on databases, selecting MongoDB Atlas for its reliability, cost, and performance. Reconstructed workflows on Miro Board, developed MongoDB schemas, and implemented API endpoints to interact with headset. Integrated Redis for optimized session data handling, enhancing performance and security through extensive API testing and validation.",
        "../researches/NUS.png",
        ["AISee Research", "Database Design", "Workflow Optimization", "API Development"],
        "",
        "https://github.com/sijan67/Exploring-the-MLP-Mixer-Architecture/blob/main/Report.pdf",
        ["https://ahlab.org/project/aisee/", "https://ahlab.org/people/sijan-poudel/"]
    ),
    
    createResearch(
        true, 
        false,
        "Jan - April 2023",
        "Exploring the MLP Mixer Architecture for Image Classification: Performance Evaluation, Tuning, and Ablation Study on MNIST Dataset",
        "In this research ,  I have reproduced and conducted ablation study on the MLP-Mixer architecture proposed in the paper  'MLP-Mixer: An all-MLP Architecture for Vision'  to determine the impact of different components of the MLP-Mixer model. From the experiments , I have found that that the MLP-Mixer model outperforms the CNN and simple MLP baselines, achieving state-of-the-art performance on the MNIST dataset. The ablation study reveals that both components of the MLP-Mixer model, channel mixing layer and token mixing layer, contributes towards the improvement of model's performance.",
        "../researches/research-1.png",
        ["Sijan Poudel"]
    ),
    createResearch(
        false, 
        false,
        "Sep - Dec 2022",
        "Comparative Study on MS Tool Extraction",
        "In this research project conducted under the guidance of Prof. Julia Rubin, I undertook some work for comparative study of MS (microservices) extraction tools. Using the Serp API and a Python script, I extracted information from Google Scholar on 1108 papers related to MS extraction tools. The collected data included details such as Author, Title, Year, Link, Publication Information, and Short Summary for each paper. To refine the dataset, I removed duplicates and selected English papers. Additionally, I employed Selenium and manual searches to retrieve venue information for all papers, as this data was not available through Serp API. Using Selenium stealth, I scraped conference and journal rankings from a designated website for each paper and refined the dataset to focus on papers associated with higher-ranked venues. For the collected paper, I also read them and classified into the various relationship types (Structural, Semantic, Evolutionary) employed by tools for microservices extraction. Furthermore, I documented and ran MS Extraction tools, including CWLViewer, Kid Tracker, OpenMRS, Opta-web-vehicle-routing app, and Thunder, detailing the processes involved in their execution for comprehensive analysis.",
        "../researches/research-2.png",
        ["Sijan Poudel"]
    ),
    createResearch(
        false, 
        false,
        "Sep 2021 - April 2022",
        "Social-Net: objective and automated assessment of chronic social isolation‐induced behavior deficits using machine learning.",
        "I conducted research under the mentorship of Dongsheng Xiao, focusing on classifying social isolation-induced behavior in mice using a machine learning model. I developed a script that integrated the TW Finch, an unsupervised segmentation method for video classification, allowing the segmentation of mice videos based on differences in mouse behavior in consecutive frames. The script I developed proved to be the fastest and most efficient algorithm utilized in our project. Following segmentation, these videos were manually labeled to identify specific types of behavior and subsequently trained using a transformer to classify new datasets. ",
        "../researches/research-3.png",
        ["Additional Keywords", "Sijan Poudel"]
    ),
    // Add more research items as needed
];

export default myResearch;
